import Link from "next/link";
import { CgvHeader } from "./CgvHeader";
import { CgvQuotes } from "./CgvQuotes";

export const CgvBody = () => {
  return (
    <div className="mx-8 flex flex-col gap-y-4 text-14 sm:mx-12 sm:text-16">
      <div className="text-center font-bold">
        <p>{`CONDITIONS GENERALES DE VENTE ABONNEMENTS ET VENTES AU NUMÉRO`}</p>
        <p>{`APPLICABLES AUX PROFESSIONNELS`}</p>
        <p>{`15 Juin 2024`}</p>
      </div>
      <ol className="grid list-outside list-decimal gap-4 text-justify [&>*]:pl-4 [&>li::marker]:font-bold">
        <CgvHeader
          headerNum={1}
          underLinedText={`Objet et champ d'application`}
        />
        <li>
          Les présentes conditions générales de vente (ci- après, les{" "}
          <CgvQuotes>Conditions Générales de Vente</CgvQuotes> ou{" "}
          <CgvQuotes>Contrat</CgvQuotes>) s’appliquent à toute Commande (telle
          que définie à l’Article 3 ci-dessous) adressée à la société CoStar UK
          Limited, une société de droit anglais, dont le siège social est situé
          au 26th Floor, The Shard, 32 London Bridge Street, Londres, SE1 9SG,
          Royaume-Uni (ci- après <CgvQuotes>BUSINESS IMMO</CgvQuotes>), par des
          clients professionnels (ci-après les <CgvQuotes>Clients</CgvQuotes>)
          concernant les abonnements et les ventes au numéro listé(e)s à la
          clause 9 ci-dessous (ci-après les <CgvQuotes>Produits</CgvQuotes>),
          sauf accord spécifique préalable à la Commande convenu par écrit entre
          BUSINESS IMMO et le Client. CoStar UK Limited est une filiale de la
          société CoStar Realty Information, Inc., une société américaine située
          1331 L Street, NW Washington, DC 20005 (ci-après,{" "}
          <CgvQuotes>CoStar</CgvQuotes>). Le Service d’abonnement sera fourni
          par CoStar UK Limited ou par des membres du groupe CoStar.
        </li>

        <li>
          BUSINESS IMMO et le Client seront ci-après désignés collectivement les{" "}
          <CgvQuotes>Parties</CgvQuotes> et individuellement une{" "}
          <CgvQuotes>Partie</CgvQuotes>.
        </li>

        <li className="font-bold">
          LES CONDITIONS GENERALES DE VENTE NE S’APPLIQUENT PAS AUX PERSONNES
          PHYSIQUES AGISSANT A DES FINS QUI N&apos;ENTRENT PAS DANS LE CADRE DE
          LEUR ACTIVITE COMMERCIALE, INDUSTRIELLE, ARTISANALE, LIBERALE OU
          AGRICOLE (CONSOMMATEURS).
        </li>

        <li>
          Toute Commande implique l&apos;acceptation sans réserve par le Client
          et son adhésion pleine et entière aux Conditions Générales de Vente,
          qui prévalent sur tout autre document du Client, et notamment sur
          toutes conditions générales d&apos;achat, sauf accord dérogatoire
          exprès et préalable de BUSINESS IMMO. Par exception, les Commande de
          BUSINESS IMMO signés par le Client prévalent sur ces Conditions
          Générales de Vente.
        </li>

        <li className="font-bold">
          L’accès au site internet BUSINESS IMMO (ci-après le Service
          d’abonnement) se fait par l’enregistrement d’une adresse email
          personnelle (type :{" "}
          <span className="text-primary">nomprenom@xxxx.fr/com/io</span>),
          toutes les autres types d’adresses génériques seront refusées. Les
          Utilisateurs Autorisés devront respecter la procédure
          d’authentification mise en place par BUSINESS IMMO, notamment par
          exemple l’authentification à multi facteur ou l’authentification
          biométrique.
        </li>

        <li>
          Par ailleurs, le Client s’engage à communiquer avec chacun de ses
          Utilisateurs Autorisés les Conditions générales d’Utilisation figurant
          en annexe à ces Conditions Générales de Vente et se porte fort de leur
          respect par ses Utilisateurs Autorisés.
        </li>

        <li>
          Le Service d&apos;abonnement ne peut être utilisé que par le nombre
          d&apos;utilisateurs indiqué sur la Commande. Sauf si le Client est une
          personne physique, et donc le seul Utilisateur autorisé, tous les
          utilisateurs (les <CgvQuotes>Utilisateurs Autorisés</CgvQuotes>)
          doivent être des personnes physiques qui (1) sont employées par le
          Client ou un Prestataire Exclusif du Client et (2) figurent sur la
          liste des Utilisateurs Autorisés de BUSINESS IMMO. Si le Client
          souhaite augmenter le nombre d’Utilisateurs autorisés, il doit passer
          Commande auprès de BUSINESS IMMO. Un{" "}
          <CgvQuotes>Prestataire Exclusif</CgvQuotes> est défini comme une
          personne physique travaillant uniquement pour le Client et non pas
          pour elle-même ou pour une autre société ayant des besoins
          d&apos;information dans le domaine de l’immobilier commercial ou
          l&apos;industrie du logement et exécutant substantiellement les mêmes
          services pour le Client qu&apos;un employé du Client. Le Client
          veillera à ce que l&apos;accès au Service d&apos;abonnement et son
          utilisation, ainsi que les noms d&apos;utilisateur et les mots de
          passe (ci-après collectivement les{" "}
          <CgvQuotes>Codes d&apos;accès</CgvQuotes>) et toute autre méthode
          d&apos;authentification utilisée pour accéder au Service
          d&apos;abonnement ne soient accessibles qu&apos;aux Utilisateurs
          autorisés, et ne permettent à personne d&apos;autre qu&apos;un
          Utilisateur autorisé d&apos;accéder, pour quelque raison que ce soit,
          au Service d&apos;abonnement ou aux Codes d&apos;accès.
        </li>

        <li>BUSINESS IMMO édite, mensuellement, le magazine « BIG ».</li>

        <li>
          BUSINESS IMMO propose une formule d’abonnement dite « BIG » comprenant
          :
          <ol className="list-disc pl-4">
            <li>
              un accès à toutes les rubriques du site internet de BUSINESS IMMO
              accessible à l’adresse www.businessimmo.com
            </li>
            <li>
              le magazine « BIG » (en versions papier ou digitale au choix de
              BUSINESS IMMO - BUSINESS IMMO peut décider à tout moment de ne
              fournir l’abonnement qu’en version digitale) et,
            </li>
          </ol>
        </li>

        <li>
          Les Produits en version papier sont uniquement livrés aux clients
          établis en France et/ou dans un État membre de l’Union européenne.
        </li>

        <CgvHeader headerNum={2} underLinedText="Durée des abonnements" />

        <li>
          La durée initiale du présent Contrat commence à la date indiquée dans
          la Commande (la « Date de Commencement ») et se poursuit pendant une
          période initiale de douze (12) mois à compter de la date de validation
          de la Commande par BUSINESS IMMO et expire à la fin de cette période
          le dernier jour du mois civil au cours duquel la Date de Commencement
          a eu lieu. (A titre d&apos;exemple, si la Date de Commencement était
          le 15 janvier 2050, avec une durée d&apos;un an, la durée initiale se
          terminerait le 31 janvier 2051).
        </li>

        <li>
          À l’issue de cette période initiale, l’abonnement se renouvellera
          tacitement par périodes successives de douze (12) mois, sauf
          dénonciation par BUSINESS IMMO ou le Client notifiée par lettre
          recommandée avec demande d’avis de réception au plus tard deux (2)
          mois avant l’échéance de la période initiale ou de chaque période de
          renouvellement.
        </li>

        <li>
          L’accès au service se fait par l’intermédiaire d’une adresse email
          personnelle, tous types d’autres adresses fera l’objet d’une
          annulation de l’abonnement.
        </li>

        <CgvHeader
          headerNum={3}
          underLinedText="Commande et Confirmation de Commande"
        />

        <li>
          Par <CgvQuotes>Commande</CgvQuotes>, il faut entendre tout ordre du
          Client à BUSINESS IMMO, portant sur un ou plusieurs Produit(s) ou sur
          l’ajout du nombre d’Utilisateurs Autorisés. Cet ordre s’effectue par
          la signature par le Client du bon de commande préparé par BUSINESS
          IMMO et renvoyé à BUSINESS IMMO soit via signature électronique ou à
          l’adresse communiquée par BUSINESS IMMO sur la Commande.
        </li>

        <li>
          Une Commande doit comprendre :
          <ol className="list-disc pl-4">
            <li>
              une description claire de son objet, conformément à l’offre
              présentée par BUSINESS IMMO ;
            </li>
            <li>
              l’identité du Client et de la personne autorisée par le Client à
              passer la Commande, ainsi que la qualité de cette dernière ;
            </li>
            <li>les informations nécessaires à la facturation du Client ;</li>
            <li>l’adresse électronique du Client ;</li>{" "}
            <li>le cas échéant, l’adresse postale précise de livraison.</li>
          </ol>
        </li>

        <li>
          Il est expressément dérogé aux dispositions des 1° à 5° de l’article
          1127-1 et de l’article 1127-2 du Code civil, conformément aux termes
          de l’article 1127-3 du même Code.
        </li>

        <li>
          BUSINESS IMMO se réserve le droit de refuser toute Commande en cas
          d’indisponibilité du Produit concerné, ou dans le cas où un Client
          effectuerait une nouvelle Commande sans avoir procédé au paiement
          d’une ou plusieurs Commandes précédentes dont le terme est échu.
        </li>

        <CgvHeader headerNum={4} underLinedText="Tarifs" />

        <li>
          Les tarifs appliqués par BUSINESS IMMO pour les ventes de Produits et
          les Utilisateurs Autorisés (les <CgvQuotes>Tarifs</CgvQuotes>) sont
          ceux indiqués sur la Commande.
        </li>

        <li>
          Les Tarifs indiqués sur la Commande sont libellés en euros (€) et
          calculés hors taxes (H.T.). Par voie de conséquence, les Tarifs seront
          majorés du taux de TVA applicable au jour de la Commande.
        </li>

        <li>
          Les Tarifs appliqués par BUSINESS IMMO à la date de la Commande sont
          indexés sur la base de l’indice SYNTEC et seront révisés à chaque date
          annuelle d&apos;anniversaire de l’abonnement par application de la
          formule suivante : P1 = P0 x (S1 / S0), dans laquelle :
          <ol>
            <li>P1 : Tarif révisé</li>
            <li>P0 : Tarif d’origine; </li>
            <li>
              S0 : indice Syntec le plus récent publié à la date de la
              précédente révision ou indice d’origine (à la date de signature de
              la Commande) ;
            </li>
            <li>S1 : indice Syntec du mois de la révision.</li>
          </ol>
        </li>

        <li>
          L’indice Syntec est disponible sur le site www. Syntec.fr (colonne de
          gauche). En cas de disparition de l&apos;indice, le calcul
          s&apos;effectuera sur l&apos;indice se substituant à l’indice SYNTEC,
          en utilisant le coefficient de corrélation nécessaire. A défaut
          d&apos;indice se substituant à l’indice SYNTEC, les Parties
          utiliseront l&apos;indice le plus proche.
        </li>

        <li>
          Les frais de transport ou de routage sont gratuits pour toute
          Commande.
        </li>

        <li>
          Par ailleurs, sans préjudice de l’augmentation tarifaire sur la base
          de l’indice Syntec comme prévu ci-avant, BUSINESS IMMO se réserve le
          droit d’augmenter ses Tarifs applicables à tout moment. BUSINESS IMMO
          devra avertir le Client au moins deux mois avant la fin de la période
          d’abonnement en cours. Si le Client n’émet pas d’objection pendant
          cette période, alors les nouveaux Tarifs seront applicables à compter
          du renouvellement de l’abonnement. A défaut, si le Client refuse les
          nouveaux tarifs, alors l’abonnement sera résilié à l’expiration de la
          période d’abonnement en cours.
        </li>

        <li>
          Les factures envoyées au Client pourront être envoyées par toute
          entité appartement au groupe CoStar.
        </li>

        <CgvHeader headerNum={5} underLinedText="Livraison des Produits" />

        <div className="font-bold underline">
          A) Livraison des Produits en version papier
        </div>

        <li>
          En plus des versions digitales, sous réserve des stipulations de
          l’article 9, le Client recevra une version papier des magazines. Le
          délai de livraison en France métropolitaine est en moyenne, compte
          tenu des délais d’acheminement, de dix (10) jours ouvrés à compter de
          la date de réception de la Commande par BUSINESS IMMO.
        </li>

        <li>
          Pour toute livraison à destination d’un territoire de l’Union
          Européenne hors France métropolitaine, le Client devra contacter
          BUSINESS IMMO pour connaître le délai de livraison.
        </li>

        <li>Ces délais ne sont donnés qu’à titre indicatif.</li>

        <div>Le délai indicatif prévu dans la Commande prévaudra.</div>

        <li>
          La livraison sera considérée comme effective à compter de la date de
          première présentation des Produits à l’adresse indiquée par le Client
          dans la Commande.
        </li>

        <li>
          Le défaut de réception des Produits par le Client ne libérera pas le
          Client de son obligation de payer le prix des Produits ainsi que les
          éventuels frais supplémentaires, en ce compris les frais de livraison
          et de stockage.
        </li>

        <li>
          Sans préjudice des dispositions à prendre vis-à- vis du transporteur,
          à défaut de réserve envoyée par lettre recommandée avec demande d’avis
          de réception à BUSINESS IMMO dans un délai de trois (3) jours, hors
          jours fériés, à compter de la date de réception des Produits par le
          Client, le Client ne pourra plus effectuer aucune réclamation auprès
          de BUSINESS IMMO, qui ne pourra être tenue responsable d’aucun dommage
          lié au transport ou à la livraison.
        </li>

        <div>
          En cas de défaut ou vice d’un magazine, le Client ne pourra réclamer
          aucun remboursement et pourra télécharger une version digitale sur le
          site de BUSINESS IMMO.
        </div>

        <div className="font-bold underline">
          B) Livraison des Produits en version digitale et accès aux sites
          internet
        </div>

        <li>
          BUSINESS IMMO communiquera au Client par courriel, à l’adresse
          indiquée par ce dernier lors de la Commande, les identifiants lui
          permettant d’accéder au contenu des sites internet et aux Produits en
          version digitale, au plus tard quatre-vingt-seize (96) heures ouvrées
          à compter de la réception de la Commande.
        </li>

        <li>
          La livraison sera considérée comme effective à compter de l’envoi du
          courriel susmentionné.
        </li>

        <li>
          <div>Codes d&apos;accès.</div>
          <ol className="mt-4">
            <li>
              (a) Le Client désignera une personne autorisée à déterminer et à
              modifier le niveau d&apos;accès de chaque Utilisateur autorisé au
              Service d&apos;abonnement et à s&apos;assurer du respect des
              présentes Conditions Générales de Vente par le Client. Aucun
              Utilisateur autorisé ne peut accéder au Service d&apos;abonnement
              en utilisant un Code d&apos;accès autre que les Codes d&apos;accès
              qui lui ont été attribués. Aucun Utilisateur autorisé ne peut
              partager les Codes d’accès qui lui ont été attribués avec une
              autre personne, ni permettre à une autre personne d&apos;utiliser
              ou d&apos;avoir accès à ses Codes d’accès. Pendant la durée de
              l’abonnement, le Client s’engage à notifier rapidement à BUSINESS
              IMMO tout changement d&apos;emploi ou de statut contractuel
              d&apos;un Utilisateur Autorisé, y compris, sans limitation, la
              cessation du contrat de travail ou du lien contractuel d&apos;un
              Utilisateur Autorisé avec le Client, et dès cette cessation, le
              Client s’engage à cesser d&apos;utiliser et détruira les Codes
              d&apos;Accès de cet Utilisateur Autorisé. Tout utilisateur
              autorisé qui cesse d&apos;être un employé ou un Prestataire
              Exclusif du client ne peut, de quelque manière que ce soit,
              utiliser les Codes d&apos;accès.{" "}
            </li>
            <li className="pt-4">
              (b) L&apos;accès de chaque Utilisateur Autorisé au Service
              d&apos;abonnement peut être limité à un ordinateur désigné et peut
              exiger que l&apos;Utilisateur Autorisé accède au Service
              d&apos;abonnement uniquement en utilisant les Codes d’accès et, si
              requis par BUSINESS IMMO, les Codes d’accès avec une
              authentification à deux facteurs, y compris, mais sans s&apos;y
              limiter, l&apos;authentification par reconnaissance faciale.
              BUSINESS IMMO n&apos;a aucune obligation de confirmer
              l&apos;identité réelle ou l&apos;autorité de toute partie accédant
              au Service d&apos;abonnement au moyen d’un Codes d’accès ou de
              toute autre méthode d&apos;authentification.
            </li>
          </ol>
        </li>

        <CgvHeader
          headerNum={6}
          underLinedText="Licence et Utilisation du Produit BUSINESS IMMO"
        />

        <li>
          Pendant la durée de l’abonnement, BUSINESS IMMO accorde au Client une
          licence non exclusive et non transférable pour utiliser uniquement les
          parties du Service d’abonnement et du Produit sous licence qui sont
          expressément identifiées dans une Commande, sous réserve des
          stipulations des présentes Conditions Générales de Vente et
          conformément à celles-ci. Seuls les Utilisateurs Autorisés peuvent
          accéder aux Produits, conformément aux présentes.
        </li>

        <li>
          Le Client peut, dans le cours ordinaire de ses affaires, partager ou
          distribuer occasionnellement à ses propres clients des quantités
          limitées d&apos;informations, de textes, de données, de photographies
          et d’autres images, contenus ou fournis dans le cadre du Service
          d&apos;abonnement et des Produits (<CgvQuotes>Informations</CgvQuotes>
          ), ainsi que des extraits limités et des parties isolées de
          prévisions, évaluations, simulations, appréciations, modèles,
          processus, méthodes, techniques, applications, procédures, formules,
          algorithmes et autres analyses liées à l&apos;industrie de
          l&apos;hébergement immobilier dans le cadre du Service
          d&apos;abonnement et des Produits (<CgvQuotes>Analyses</CgvQuotes>) («
          Informations » et « Analyses » seront ci-après désignées
          collectivement <CgvQuotes>Extraits de BUSINESS IMMO</CgvQuotes>),
          lorsque ces Extraits de BUSINESS IMMO sont contenus dans ses propres
          rapports, analyses ou présentations à destination de ses clients ou
          leur sont accessoires (<CgvQuotes>Eléments du client</CgvQuotes>), à
          condition que : (i) ces Extraits de BUSINESS IMMO ne fassent que
          soutenir/compléter la substance des Eléments du client ; (ii) le
          Client soit responsable de toute distribution d&apos;Extraits de
          BUSINESS IMMO ; (iii) le Client reconnaisse toujours BUSINESS IMMO
          comme la source des Extraits de BUSINESS IMMO au sein des Eléments du
          client ; (iv) les Eléments du client n&apos;incluent pas des copies
          intégrales ou de parties substantielles de toute Information ou
          Analyse et ne puisse contenir que des quantités limitées
          d&apos;informations spécifiques à l&apos;industrie hôtelière et/ou aux
          immeubles et aux locataires ; et (v) les Eléments du client ne soit
          pas distribués commercialement ou généralement. Les parties des
          services qui sont licenciées en vertu des présentes, y compris les
          mises à jour ou les modifications qui y sont apportées, et toute
          information dérivée de l&apos;utilisation du site Web de BUSINESS
          IMMO, de l&apos;Analyse ou de l&apos;Information, y compris à la suite
          de la vérification de toute partie du site Web de BUSINESS IMMO, de
          l&apos;Analyse ou de l&apos;Information par le Client, sont
          collectivement désignées dans cette clause les{" "}
          <CgvQuotes>Produit BUSINESS IMMO</CgvQuotes>.{" "}
        </li>

        <li>
          Sous réserve des restrictions contenues dans cet article 6 (notamment
          aux clauses 35 et 36), le Client est autorisé à imprimer les
          Informations ou les copier dans des programmes de traitement de texte,
          de tableur, de présentation ou des progiciels de productivité
          d&apos;usage général, qu&apos;ils soient installés sur un ordinateur
          de bureau, sur un téléphone mobile ou dans un cloud (ou tout autre
          programme logiciel avec le consentement écrit exprès de BUSINESS
          IMMO), tant que le niveau d&apos;information imprimé ou copié est
          raisonnablement adapté aux besoins du Client, non substantiel, dans le
          cours normal des affaires du Client, et utilisé en conformité avec
          cette clause. Aucun de ces programmes ou solutions de stockage ne peut
          être utilisé pour créer une base de données consultable et/ou
          concurrente de tout ou partie du Produit BUSINESS IMMO, ce qui, pour
          éviter tout doute, signifie une base de données qui permet à ses
          utilisateurs de rechercher et/ou d&apos;extraire des dossiers
          individuels ou des données de ces dossiers. De même, les Produits
          BUSINESS IMMO ne doivent pas être exposés à des environnements rendant
          susceptibles que des tiers y accèdent ou les utilisent directement ou
          indirectement, y compris sans limitation des outils ouverts
          d’intelligence artificielle.
        </li>

        <li>
          A l&apos;exception de ce qui est prévu à cet article 6, ou de ce qui
          peut être autrement convenu par les Parties, le Client ne doit pas
          distribuer, divulguer, copier, reproduire, rendre disponible,
          communiquer au public par télécommunication, afficher, publier,
          transmettre, céder, sous-licencier, transférer, donner accès, utiliser
          ou vendre, directement ou indirectement (y compris, sans limitation,
          sous forme électronique), toute Information, Analyse ou toute autre
          partie du Produit BUSINESS IMMO, ou modifier, adapter ou créer des
          travaux dérivés du Produit BUSINESS IMMO.
        </li>

        <li>
          Nonobstant toute autre disposition des présentes, le Client ne doit
          pas :
          <ol>
            <li>
              (1) télécharger, afficher ou transmettre de quelque manière que ce
              soit tout ou partie du Produit BUSINESS IMMO sur, ou fournir un
              accès à tout ou partie du Produit BUSINESS IMMO par le biais
              d&apos;Internet, tout système de tableau d&apos;affichage
              électronique, tout réseau électronique, tout service de
              référencement ou tout autre dispositif de partage de données non
              limité exclusivement au Client et aux Utilisateurs Autorisés, à
              l&apos;exception du fait que le Client peut envoyer par e-mail un
              rapport contenant des Informations ou des Extraits de BUSINESS
              IMMO conformes à la clause 35 à un nombre limité de ses clients et
              clients potentiels ;{" "}
            </li>
            <li>
              (2) utiliser tout ou partie du Produit BUSINESS IMMO pour créer,
              directement ou indirectement, toute base de données ou produit
              sans l&apos;autorisation écrite exprès de BUSINESS IMMO ;{" "}
            </li>
            <li>
              (3) accéder ou utiliser le Produit BUSINESS IMMO si le Client est
              un concurrent direct ou indirect de BUSINESS IMMO ou de fournir
              tout ou partie du Produit BUSINESS IMMO à tout concurrent direct
              ou indirect de BUSINESS IMMO ;{" "}
            </li>
            <li>
              (4) stocker, copier ou exporter tout ou partie du Produit BUSINESS
              IMMO dans une base de données ou un autre logiciel, à
              l&apos;exception des dispositions de la clause 35;{" "}
            </li>
            <li>
              (5) modifier, fusionner, gratter, désassembler ou rétro concevoir
              tout ou partie du Produit BUSINESS IMMO ;
            </li>
            <li>
              (6) utiliser, reproduire, publier ou compiler toute Information ou
              Analyse dans le but de vendre ou d&apos;accorder une licence pour
              cette information ou rendre cette information accessible au public
              ;{" "}
            </li>
            <li>
              (7) utiliser ou distribuer des Informations ou Analyses qui ont
              été vérifiées ou confirmées par le Client dans le but de
              développer ou de contribuer au développement de toute base de
              données, produit ou service ;{" "}
            </li>
            <li>
              (8) utiliser tout ou partie du Produit BUSINESS IMMO en violation
              des lois et réglementations applicables.
            </li>
          </ol>
        </li>

        <CgvHeader
          headerNum={7}
          underLinedText="Évolution significative ou suppression des Produits et/ou services dans le cadre d’un abonnement"
        />

        <li>
          En cas d’évolution significative ou de suppression de l’un ou de
          plusieurs Produit(s) faisant l’objet d’un abonnement, BUSINESS IMMO en
          informera le Client.
        </li>

        <li>
          Le Client informera BUSINESS IMMO de sa volonté de migrer l’abonnement
          vers le nouveau Produit ou le cas échéant le Produit le plus proche
          dans une thématique similaire, ou de résilier le contrat et ce, dans
          un délai de dix (10) jours à compter de l’information donnée par
          BUSINESS IMMO. En cas de résiliation, BUSINESS IMMO remboursera tous
          frais prépayés et non encore consommés.
        </li>

        <li>
          A défaut de réponse du Client dans ce délai, BUSINESS IMMO procédera
          d’office au remplacement du Produit par le nouveau Produit ou le cas
          échéant par le Produit le plus proche dans une thématique similaire.
        </li>

        <CgvHeader headerNum={8} underLinedText="Modalités de paiement" />

        <li>
          BUSINESS IMMO peut envoyer des factures pour ces frais par courrier
          électronique, par courrier postal ou les deux. Tous ces frais sont
          facturés à l’avance et sont dus nets dans un délai de trente (30)
          jours et sont payables par virement bancaire aux coordonnées indiquées
          sur la facture.
        </li>

        <li>
          Tout retard ou défaut de paiement entraînera automatiquement le droit
          pour BUSINESS IMMO d’exiger du Client :
          <ol className="mt-4 list-disc">
            <li>
              le paiement de pénalités de retard, égales à trois fois le taux
              d’intérêt légal pour l’année en cours sur le solde des sommes dues
              à compter de la date d’échéance du paiement et jusqu’au paiement
              intégral, exigibles de plein droit sans qu’un quelconque appel ou
              une quelconque mise en demeure préalable ne soient nécessaires ;{" "}
            </li>
            <li>
              le paiement d’une indemnité forfaitaire pour frais de recouvrement
              d’un montant de quarante (40) euros conformément aux articles L.
              441-10 et D. 441-5 du Code de commerce. Dans le cas où les frais
              de recouvrement exposés seraient supérieurs à ce montant, BUSINESS
              IMMO pourra demander une indemnisation complémentaire sur
              justification.
            </li>
          </ol>
        </li>

        <li>
          Toute facture dont le recouvrement rendrait nécessaire l’intervention
          du service contentieux de BUSINESS IMMO sera majorée, au titre de
          clause pénale au sens de l’article 1231-5 du Code civil, d’une
          indemnité fixée forfaitairement à vingt- cinq pourcent (25%) du
          montant de la ou des facture(s) impayée(s).
        </li>

        <li>
          Tout retard ou défaut de paiement entraînera :
          <ol className="m-4 list-disc">
            <li>
              l’absence de traitement par BUSINESS IMMO des Commandes adressées
              par le Client ;
            </li>
            <li>
              la possibilité pour BUSINESS IMMO de faire usage de sa faculté de
              résiliation conformément à l’article 12 ci-dessous ;
            </li>
            <li>
              la possibilité pour BUSINESS IMMO de suspendre l’accès du Client à
              toutes les bases et applications mobiles.
            </li>
          </ol>
        </li>

        <li>
          De même, sans préjudice des autres recours prévus aux présentes, toute
          utilisation supplémentaire de Produits en violation des droits
          concédés aux présentes ou dans un bon de commande, ou utilisation par
          un nombre supérieurs d’Utilisateurs Autorisés que le nombre commandés
          via les Commandes donnera lieu à une facture de régularisation par
          BUSINESS IMMO depuis le premier usage en violation afin que le Client
          acquière les droits correspondants, aux prix alors en vigueur de
          BUSINESS IMMO.
        </li>

        <CgvHeader headerNum={9} underLinedText="Aucune garantie" />

        <li>
          BIEN QUE BUSINESS IMMO S&apos;EFFORCE DE FOURNIR UN PRODUIT EXACT, LE
          PRODUIT CONCÉDÉ EN LICENCE AU TITRE DES PRÉSENTES EST FOURNIS « EN
          L&apos;ÉTAT », « AVEC TOUS LES DÉFAUTS » ET « TELS QUE DISPONIBLES ».
          BUSINESS IMMO NE FAIT, ET PAR LA PRÉSENTE EXCLUENT ET REJETTENT
          EXPRESSÉMENT, TOUTE DÉCLARATION, GARANTIE OU ASSURANCE DE QUELQUE
          NATURE QUE CE SOIT, QU&apos;ELLE SOIT, Y COMPRIS SANS LIMITATION (a)
          LA QUALITÉ MARCHANDE, ET L&apos;ADAPTATION À UN USAGE PARTICULIER,
          L’ABSENCE DE CONTREFACON, b) LA QUALITE, L&apos;EXACTITUDE, LA
          PONCTUALITE OU L&apos;EXHAUSTIVITE DU PRODUIT SOUS LICENCE, C) LES
          CONSEQUENCES D&apos;UNE TRANSACTION, D&apos;UNE EXECUTION OU D&apos;UN
          USAGE COMMERCIAL, D) LA CONFORMITE DU PRODUIT SOUS LICENCE A QUELQUE
          FONCTION QUE CE SOIT, D) LA CONFORMITE DU PRODUIT SOUS LICENCE A TOUTE
          FONCTION, DEMONSTRATION OU PROMESSE DE BUSINESS IMMO NON STIPULEE AUX
          PRESENTES OU DANS UNE COMMANDE, ET (E) LE FAIT QUE L&apos;ACCES AU
          PRODUIT SOUS LICENCE OU SON UTILISATION SERONT ININTERROMPUS, EXEMPTS
          D&apos;ERREURS OU PARFAITEMENT SURS. LES ANALYSES ET LES RAPPORTS DE
          MARCHÉ DE BUSINES IMMO CONTENUS DANS LE PRODUIT SOUS LICENCE PEUVENT
          INCLURE, SANS S&apos;Y LIMITER, DES DÉCLARATIONS CONCERNANT LES
          CONVICTIONS, LES ATTENTES, LES INTENTIONS OU LES STRATÉGIES ACTUELLES
          OU FUTURES DE BUSINESS IMMO CONCERNANT DES MARCHÉS IMMOBILIERS
          PARTICULIERS. L&apos;ANALYSE ET LES RAPPORTS DE MARCHÉ DE BUSINESS
          IMMO SONT SOUMIS À DE NOMBREUX RISQUES ET INCERTITUDES SUSCEPTIBLES
          D&apos;ENTRAÎNER UNE DIFFÉRENCE SUBSTANTIELLES ENTRE LES RÉSULTATS
          RÉELS ET L&apos;ANALYSE ET LES RAPPORTS DE MARCHÉ DE BUSINESS IMMO. LE
          CLIENT COMPREND QUE L&apos;ANALYSE ET LES RAPPORTS DE MARCHÉ DE
          BUSINESS IMMO CONTENUS DANS LE PRODUIT SONT CONSIDÉRÉS COMME ÉTANT À
          LA POINTE DE LA TECHNOLOGIE ET QU&apos;EN RAISON DE LEUR PÉRIODE
          D&apos;UTILISATION LIMITÉE, LEUR DEGRÉ D&apos;EXACTITUDE DANS LA
          COMMUNICATION D&apos;INFORMATIONS SUR LE MARCHÉ IMMOBILIER ET
          L&apos;ÉTABLISSEMENT DE PRÉVISIONS N&apos;EST PAS PROUVÉ. LE CLIENT NE
          PEUT TENIR LES BUSINESS IMMO OU SES CONCÉDANTS POUR RESPONSABLES DES
          ERREURS COMMISES DANS LA COMMUNICATION, L&apos;ÉVALUATION,
          L&apos;ANALYSE, LA SIMULATION OU LA PRÉVISION D&apos;INFORMATIONS SUR
          LE MARCHÉ IMMOBILIER, NI DES INFORMATIONS, ANALYSES OU RAPPORTS DE
          MARCHÉ COSTAR CONTENUS DANS LE PRODUIT SOUS LICENCE.
        </li>

        <CgvHeader headerNum={10} underLinedText="Responsabilité" />

        <div className="font-bold underline">10.1 Responsabilité du Client</div>

        <li>
          Le Client est tenu personnellement responsable de tout manquement aux
          dispositions des Conditions Générales de Vente, sans préjudice de
          toutes autres poursuites.
        </li>

        <div className="font-bold underline">
          10.2 Responsabilité de BUSINESS IMMO
        </div>

        <li className="font-bold">
          LE CLIENT ACCEPTE ET RECONNAÎT QUE, DANS LA MESURE PERMISE PAR LA LOI,
          BUSINESS IMMO ET SES SOCIÉTÉS AFFILIEES, FILIALES, DIRIGEANTS
          RESPECTIFS, EMPLOYÉS ET FOURNISSEURS TIERS (COLLECTIVEMENT, LES «
          PARTIES DE BUSINESS IMMO ») NE SERONT PAS TENUS RESPONSABLES DES
          PERTES, COÛTS OU DOMMAGES SUBIS OU ENCOURUS PAR LE CLIENT OU TOUT
          AUTRE TIERS, Y COMPRIS, MAIS SANS S’Y LIMITER, CEUX DÉCOULANT DE OU
          LIÉS À DES DÉFAUTS, INTERRUPTIONS OU RETARDS DANS LES PRODUITS, À DES
          INEXACTITUDES, ERREURS OU OMISSIONS DANS LES INFORMATIONS CONTENUES
          DANS LES PRODUITS, QUELLE QUE SOIT LA MANIÈRE DONT CES DÉFAUTS,
          INTERRUPTIONS, RETARDS, INEXACTITUDES, ERREURS OU OMISSIONS
          SURVIENNENT, OU POUR TOUTE UTILISATION NON AUTORISÉE DES PRODUITS.
        </li>

        <li className="font-bold">
          LA RESPONSABILITÉ GLOBALE ET CUMULATIVE DE BUSINESS IMMO DANS LE CADRE
          DES PRÉSENTES ET DE L’UTILISATION DES PRODUITS SERA LIMITÉE AUX
          DOMMAGES DIRECTS RÉELS SUBIS PAR LE CLIENT, LE CAS ÉCHÉANT, QUI NE
          POURRONT EN AUCUN CAS DÉPASSER LE MONTANT TOTAL DES SOMMES
          EFFECTIVEMENT PAYÉES PAR LE CLIENT À BUSINESS IMMO DANS LE CADRE DES
          PRESENTES AU COURS DE LA PÉRIODE DE DOUZE MOIS PRÉCÉDANT IMMÉDIATEMENT
          LA DATE DE SURVENANCE DE LA RÉCLAMATION. LE RECOUVREMENT DE CE MONTANT
          SERA LE SEUL ET UNIQUE RECOURS DU CLIENT.{" "}
        </li>

        <li className="font-bold">
          EN AUCUN CAS, BUSINESS IMMO NE SERA RESPONSABLE DES PREJUDICES
          INDIRECTS SUBIS PAR LE CLIENT, AINSI QUE DES PERTE COMMERCIALES (Y
          COMPRIS TOUTES PERTES DE PROFIT, DE BENEFICES, TOUT MANQUE A GAGNER OU
          D’ECONOMIES ESPEREES, TOUTE PERTE DE CLIENTELE), Y COMPRIS, SANS
          LIMITATION, TOUT DOMMAGE DÉCOULANT DE, BASÉ SUR, OU EN RELATION AVEC
          LES PRESENTES OU TOUTE UTILISATION DES PRODUITS, MÊME SI BUSINESS IMMO
          A ÉTÉ INFORMÉ DE LA POSSIBILITÉ DE TELS DOMMAGES.{" "}
        </li>

        <li className="font-bold">
          BUSINESS IMMO NE SERA EN AUCUN CAS RESPONSABLE D’UN EMPÊCHEMENT OU
          D’UNE LIMITATION LORS DE L’EXECUTION DES PRESENTES EN RAISON D’UN CAS
          DE FORCE MAJEURE, ET, PLUS GENERALEMENT, DU FAIT DU CLIENT, D’UN
          UTILISATEUR OU D’UN TIERS.
        </li>

        <li className="font-bold">
          SOUS RESERVE DE TOUTE DISPOSITION D’ORDRE PUBLIC, TOUTE ACTION EN
          RESPONSABILITE DÉCOULANT DES PRESENTES OU S’Y RAPPORTANT NE POURRA
          ÊTRE INTRODUITE PAR LE CLIENT PLUS D’UN (1) AN APRÈS LA SURVENANCE DE
          SON FAIT GENERATEUR.
        </li>

        <li className="font-bold">
          NI LES STIPULATIONS QUI PRÉCÈDENT, NI AUCUNE AUTRE STIPULATION DES
          PRESENTES CONDITIONS GENERALES DE VENTE, N’EXCLUENT OU NE LIMITENT LA
          RESPONSABILITÉ D’UNE PERSONNE POUR (1) LE DÉCÈS OU LES BLESSURES
          CAUSÉS PAR SA NÉGLIGENCE, (2) LA FRAUDE OU LE DOL, (3) LA FAUTE
          INTENTIONNELLE OU LOURDE OU (4) TOUTE RESPONSABILITÉ QUI NE PEUT ÊTRE
          LIMITÉE OU EXCLUE PAR LA LOI.
        </li>

        <CgvHeader headerNum={11} underLinedText="Indemnisation" />

        <li>
          <div>
            <span className="underline">Contrefaçon.</span> Si un Produit fait
            l&apos;objet d&apos;une réclamation pour violation de droits
            d&apos;auteur, de marques, de secrets commerciaux ou de brevets, ou
            si BUSINESS IMMO estime raisonnablement qu&apos;un tel événement est
            susceptible de se produire, BUSINESS IMMO, à ses frais et à sa
            discrétion, s&apos;engage à (a) garantir au Client le droit de
            continuer à utiliser le Produit; (b) remplacer ou modifier le
            Produit afin de le rendre non contrefaisant ; ou (c) si BUSINESS
            IMMO détermine, à sa seule discrétion, qu&apos;il n&apos;est pas
            commercialement possible pour BUSINESS IMMO de réaliser les options
            (a) ou (b) ci-dessus, BUSINESS IMMO aura le droit de résilier le
            présent Contrat et de fournir au Client un remboursement au prorata
            de toute partie payée à l&apos;avance des frais et Tarifs (le cas
            échéant). Ce qui précède constitue l&apos;entière responsabilité de
            BUSINESS IMMO, et le seul et unique recours du Client, en ce qui
            concerne les violations des droits d’auteur, des marques, des
            secrets commerciaux ou des brevets par les Services.
          </div>

          <div className="mt-4">
            Sauf obligation légale, BUSINESS IMMO ne s’engage à aucune autre
            garantie que celle stipulée ci-dessus. Le présent article 11 ne
            s&apos;applique pas et BUSINESS IMMO ne sera donc tenu d’aucune
            garantie dans la mesure où les Dommages résultent : (a) d&apos;une
            utilisation des Produits contraire aux conditions énoncées aux
            présentes ou aux instructions fournies par BUSINESS IMMO au Client;
            (b) de la combinaison, du fonctionnement ou de l&apos;utilisation
            des Produits avec tout produit ou service non fourni par BUSINESS
            IMMO ; ou (c) de toute donnée fournie à BUSINESS IMMO par une tierce
            partie. Le Client accepte de défendre, d’indemniser et de dégager de
            toute responsabilité BUSINESS IMMO, ses affiliés, ses filiales et
            ses cessionnaires approuvés, ainsi que leurs partenaires,
            directeurs, responsables, employés et agents pour tous les coûts et
            dépenses, y compris, mais sans s’y limiter, les honoraires d’avocat,
            relatifs à la défense et/ou aux négociations relatives au règlement
            amiable de toute réclamation, demande ou action, en cours ou
            terminée, résultant de ou liée à l’utilisation par le Client des
            Produits en violation des présentes Conditions Générales de Vente, y
            compris, mais sans s’y limiter, une réclamation en vertu d’une loi,
            d’un règlement ou d’une réglementation (une{" "}
            <CgvQuotes>Réclamation</CgvQuotes>) et s’engage à payer toutes les
            sommes dues au titre de tout jugement ou protocole d’accord fondé
            sur une Réclamation, à condition que BUSINESS IMMO donne au Client
            une notification écrite rapide de la Réclamation (à condition
            toutefois que le défaut de notification de BUSINESS IMMO ne libère
            pas le Client de ses obligations d’indemnisation, sauf dans la
            mesure où il est lésé par celle-ci), le contrôle exclusif des
            négociations et/ou de la procédure, et, aux frais du Client, une
            coopération, des informations et une assistance raisonnable dans la
            défense et/ou les négociations relatives au règlement amiable.
            BUSINESS IMMO peut, à ses propres frais, apporter une assistance
            raisonnable à cette défense et/ou aux négociations relatives au
            règlement amiable si elle le souhaite, à condition que le Client
            contrôle cette défense et/ou toutes les négociations relatives au
            règlement amiable d’une telle Réclamation. La présente section
            survivra à l’expiration ou à la résiliation des présentes pour
            quelque raison que ce soit.
          </div>
        </li>

        <CgvHeader headerNum={12} underLinedText="Suspension et Résiliation" />

        <li>
          Chacune des deux Parties pourra résilier ces Conditions Générales et
          tous abonnements ou commandes si l’autre Partie :
          <ol>
            <li>
              i. Commet un manquement grave à ses obligations qui n’est pas
              remédié au bout de 30 jours calendaires après mise en demeure par
              écrit envoyée par l’autre Partie signalant l’obligation violée{" "}
            </li>
            <li>
              ii. Ouvre une procédure collective telle que la liquidation ou le
              redressement judiciaire, sous réserve de la décision de
              l’administrateur ou du liquidateur sur la poursuite des contrats
              en cours.
            </li>
          </ol>
        </li>

        <li>
          En cas de retard de paiement du Client, dix (10) jours après mise en
          demeure restée infructueuse, BUSINESS IMMO pourra soit suspendre
          l’abonnement et/ou résilier le contrat à sa seule discrétion (sur
          notification écrite, un email suffisant), sans préjudice de tous
          autres dommages et intérêts qui pourraient lui être dus.
        </li>

        <li>
          Par ailleurs, sans préjudice de ses autres droits, BUSINESS IMMO se
          réserve le droit soit de suspendre soit de résilier un abonnement
          (pour tous les Utilisateurs) sans préavis ni indemnité, sur
          notification écrite (un email suffisant) dans les cas suivants :
          <ol className="mt-4">
            <li className="mt-4">
              i. Si BUSINESS IMMO détermine de bonne foi que le Client ou l’un
              de ses Utilisateurs Autorisés a violé les droits concédés au titre
              de l’abonnement (par exemple, utilisation par des utilisateurs
              non-autorisés ou par un nombre supérieur d’Utilisateurs Autorisés
              à celui commandé). Il est entendu que dans ce cas, les Parties
              écartent l’application des articles 1224 et suivant du Code civil
              sur le formalisme de la résolution. BUSINESS IMMO réactivera
              l’Abonnement une fois que le Client aura réglé les droits
              correspondants. Par ailleurs, même en cas de suspension de
              l’abonnement en vertu de cette clause, le Client reste redevable
              de tous les Tarifs ;{" "}
            </li>

            <li className="mt-4">
              ii. un usage d’un site internet de BUSINESS IMMO par le Client ou
              l’un de ses Utilisateurs Autorisés pouvant porter préjudice à un
              tiers ou qui serait contraire à l’ordre public ou aux bonnes mœurs
              ou constituant un acte de piratage ou une tentative d’utilisation
              illicite des informations circulant sur le réseau.{" "}
            </li>

            <li className="mt-4">
              iii. Le Client est ou devient directement ou indirectement détenu
              ou contrôlé (à hauteur de 50 % ou plus au total) par une personne
              ou une entité qui est : désignée dans le cadre d’un programme de
              sanctions économiques administré par le Conseil de sécurité des
              Nations Unies, l’Office of Foreign Assets Control (OFAC) du
              Département du Trésor des États-Unis (y compris, mais sans
              s&apos;y limiter, les Specially Designated Nationals and Blocked
              Persons List et la Sectoral Sanctions Identifications List), ou le
              Royaume-Uni (en vertu de la Sanctions and Anti-Money Laundering
              Act 2018), ou encore en vertu des règlements européens (notamment
              sur le gel des avoirs) en vigueur ou qui est située, organisée ou
              résidente dans un pays ou un territoire qui est, ou dont le
              gouvernement est actuellement, la cible d&apos;embargos globaux
              imposés par le Conseil de sécurité des Nations Unies, le
              gouvernement des États-Unis, l’Union européenne ou le Royaume-Uni
              ; qui figure sur la liste des entités administrée par la Liste
              récapitulative du Conseil de sécurité des Nations unies, le Bureau
              de l&apos;industrie et de la sécurité du ministère américain du
              commerce ou la liste des sanctions ou de gels des avoirs de
              l’Union européenne, du Royaume-Uni administrée par le Bureau des
              affaires étrangères, du Commonwealth et du développement du
              Royaume-Uni ; ou qui est en violation flagrante des lois
              applicables en matière de trafic d&apos;êtres humains ou de
              travail des enfants.
            </li>
          </ol>
        </li>

        <li>
          <p className="font-bold">
            Conséquences en cas de résiliation ou non-renouvellement
          </p>
          <p className="mt-4">
            En cas de résiliation ou de non-renouvellement des présentes
            Conditions Générales de Vente ou de toute commande, le Client ne
            pourra plus utiliser aucune partie des Produits et sites sous
            licence de quelque manière que ce soit.
          </p>
          <p className="mt-4">
            Dans les dix (10) jours suivant la date effective de résiliation ou
            de non-renouvellement, le Client devra supprimer ou détruire
            définitivement tous les éléments des Produits dont il a le contrôle.
            Toutefois, le Client ne sera pas tenu de supprimer de ses fichiers
            papiers, électroniques ou de courriers électroniques les
            informations que les Utilisateurs Autorisés du Client ont
            incorporées dans leurs propres rapports, analyses ou autres
            documents du Titulaire de la licence conformément aux termes des
            présentes Conditions et qui sont contenues dans ces fichiers papier,
            électroniques ou de courrier électronique, tant que ces documents
            sont conservés uniquement à des fins ordinaires de sauvegarde des
            systèmes d&apos;entreprise, pour se conformer à des obligations
            légales ou réglementaires et ne sont pas utilisés, copiés,
            distribués ou affichés à des fins de recherche ou de marketing
            interne, pour établir, alimenter ou maintenir un service
            d&apos;information sur l&apos;immobilier commercial ou une autre
            base de données consultable, ou à d&apos;autres fins.
          </p>

          <p className="mt-4">
            A la demande de BUSINESS IMMO, le Client devra certifier son respect
            des termes de cette clause par un écrit qui soit raisonnablement
            satisfaisant pour BUSINESS IMMO. BUSINESS IMMO pourra, à ses frais,
            vérifier le respect par le Client de la présente clause et des
            autres termes des présentes Conditions générales (y compris une fois
            celles-ci résiliées ou terminées), à condition que cette
            vérification soit effectuée sous la supervision raisonnable du
            Client et que ce dernier coopère à la conduite de cette
            vérification.
          </p>

          <p className="mt-4">
            Par ailleurs, sauf en cas de résiliation aux torts de BUSINESS IMMO,
            tous les Tarifs et prix payables par le Client ou qui auraient été
            dus par le Client jusqu’à la fin de la période en cours deviennent
            immédiatement exigibles et payables par le Client.
          </p>
        </li>
        <CgvHeader headerNum={13} underLinedText="Réserve de Propriété" />

        <li>
          Le Client reconnaît que le Produit sous Licence est composé de données
          qui sont la propriété de BUSINESS IMMO ou de ses concédants et que
          BUSINESS IMMO et ses concédants ont et conserveront la propriété
          exclusive de tous les droits de propriété sur le Produit sous Licence,
          y compris tous les droits de propriété intellectuelle des États-Unis,
          du Royaume-Uni, du Canada, de l&apos;Union européenne ou d&apos;autres
          pays, ainsi que d&apos;autres droits tels que les brevets, les marques
          déposées, les droits d&apos;auteur et les secrets commerciaux. Il
          s&apos;agit d&apos;un contrat de licence (abonnement) et non d&apos;un
          contrat de vente. Le preneur de licence n&apos;a aucun droit ou
          intérêt sur une quelconque partie du produit sous licence, à
          l&apos;exception du droit d&apos;utiliser le produit sous licence tel
          qu&apos;il est défini dans les présentes Conditions Générales de
          Vente. Le Client reconnaît que le Produit constitue la propriété et
          une information confidentielle protégée par le droit d&apos;auteur de
          BUSINESS IMMO et de ses concédants et s&apos;engage à (a) se conformer
          à toutes les lois sur le droit d&apos;auteur, les marques, les secrets
          commerciaux, les brevets, les contrats et autres lois nécessaires à la
          protection de tous les droits relatifs à cette information, (b) ne pas
          contester la propriété de BUSINESS IMMO et de ses concédants (ou la
          validité ou l&apos;applicabilité de leurs droits sur ces
          informations), et (c) ne pas supprimer, dissimuler, oblitérer ou
          contourner tout droit d&apos;auteur ou autre information de gestion
          des droits (copyright), avis, licence ou mesure technologique
          anti-piratage inclus dans le Produit. Le Client est responsable de
          toute violation des dispositions des présentes Conditions Générales de
          Vente par un Utilisateur autorisé et de toute utilisation non
          autorisée du Produit par ses employés, sous-traitants, affiliés et
          agents. Le Client ne peut utiliser ou reproduire aucune marque, marque
          de service ou nom commercial de BUSINESS IMMO ou de CoStar ou de ses
          concédants sans l&apos;accord écrit de BUSINESS IMMO. Aucune
          stipulation des présentes Conditions Générales de Vente n&apos;empêche
          BUSINESS IMMO d&apos;utiliser librement, à quelque fin que ce soit et
          sans compensation, toute idée, suggestion, amélioration ou autre
          retour d&apos;information du Client concernant le Produit ou de
          nouveaux produits, fonctionnalités ou outils.
        </li>

        <CgvHeader headerNum={14} underLinedText="Données Personnelles" />

        <li>
          BUSINESS IMMO est amenée à collecter et à traiter certaines données à
          caractère personnel du Client ou de ses représentants personnes
          physiques ou des Utilisateurs Autorisés (les{" "}
          <CgvQuotes>Données Personnelles</CgvQuotes>) principalement pour les
          besoins de la gestion administrative de ce contrat et la fourniture
          des services.
        </li>

        <li>
          BUSINESS IMMO, responsable du traitement des Données Personnelles,
          assure au Client une collecte et un traitement de ses Données
          Personnelles dans le respect des dispositions de la loi n°78-17 du 6
          janvier 1978 relative à l&apos;informatique, aux fichiers et aux
          libertés (la <CgvQuotes>Loi Informatique et Libertés</CgvQuotes>) et
          du Règlement n°2016/679 du Parlement européen et du Conseil du 27
          avril 2016 (le <CgvQuotes>Règlement</CgvQuotes>).
        </li>

        <li>
          Le Client est invité à consulter les Mentions Légales et/ou la
          politique de confidentialité du site internet de BUSINESS IMMO,
          auxquelles il est renvoyé pour un exposé plus détaillé des conditions
          de collecte et de traitement des Données Personnelles.
        </li>

        <li>
          <p>
            Sauf opposition du Client et des Utilisateurs Autorisés BUSINESS
            IMMO pourra lui/leur envoyer des communications commerciales sur ses
            produits et services. Le Client dispose du droit de retirer son
            consentement à tout moment.
          </p>
          <p className="mt-4">
            Un lien de désinscription sera présent dans les newsletters et
            courriels marketing envoyés par BUSINESS IMMO au Client.
          </p>
        </li>

        <CgvHeader headerNum={15} underLinedText="Intégralité / Intégrité" />

        <li>
          Les relations entre les Parties sont régies par les présentes
          Conditions Générales de Vente et la Commande.
        </li>

        <li>
          En cas de contradiction entre les présentes Conditions Générales de
          Vente et tout autre document portant sur un même objet, BUSINESS IMMO
          et le Client conviennent expressément que les Conditions Générales de
          Vente s’appliqueront en priorité, sauf indication contraire présente
          dans les Conditions Générales de Vente.
        </li>

        <li>
          La nullité ou l&apos;inapplicabilité de l&apos;une quelconque des
          stipulations des Conditions Générales de Vente n&apos;emportera pas la
          nullité ou l’inapplicabilité des autres stipulations qui conserveront
          toute leur force et leur portée.
        </li>

        <CgvHeader headerNum={16} underLinedText="Invalidité" />

        <li>
          Si une ou plusieurs stipulations des Conditions Générales de Vente
          sont invalidées, les autres stipulations garderont toute leur force et
          leur portée.
        </li>

        <CgvHeader headerNum={17} underLinedText="Absence de renonciation" />

        <li>
          Le fait pour l’une des Parties de ne pas se prévaloir d’un manquement
          auprès de l’autre Partie à l’une quelconque des obligations visées aux
          présentes ne saurait valoir renonciation à l’obligation en cause.
        </li>

        <CgvHeader
          headerNum={18}
          underLinedText="Modification des Conditions Générales de Vente"
        />

        <li>
          Les Conditions Générales de Vente pourront être modifiées à tout
          moment à l’initiative de BUSINESS IMMO.
        </li>

        <li>
          Les Conditions Générales de Vente applicables sont celles en vigueur à
          la date de la Commande.
        </li>

        <li>
          Les Conditions Générales de Vente sont consultables sur le site
          internet de BUSINESS IMMO.
        </li>

        <li>
          Le Client est invité à imprimer sans délai et conserver une copie de
          la version des Conditions Générales de Vente qui lui est applicable
          pour ses archives.
        </li>

        <CgvHeader
          headerNum={19}
          underLinedText="Réclamations et notifications"
        />

        <li>
          <p>
            Toutes réclamations par le Client doivent être adressées par lettre
            recommandée avec demande d’avis de réception à l’adresse suivante :
          </p>
          <p>
            <div>BUSINESS IMMO,</div>
            <div>A l’attention du Service Abonnement</div>
            <div>81 Rue Taitbout 75009 Paris</div>
          </p>

          <p className="mt-4">
            Toutes les notifications envoyées par BUSINESS IMMO seront réputées
            avoir été données si elles sont remises en mains propres ou par
            courrier électronique, le jour de la remise, si elles sont envoyées
            par courrier recommandé ou certifié, trois (3) jours après la date
            d&apos;envoi, si elles sont remises par une société de courrier de
            nuit reconnue, un jour après l&apos;envoi, et si elles sont remises
            par courrier international de nuit, quatre (4) jours après
            l&apos;envoi. Le Client accepte que BUSINESS IMMO puisse inclure des
            notifications sur les factures envoyées au Client par courrier ou
            par email.{" "}
          </p>
        </li>

        <CgvHeader headerNum={20} underLinedText="Cession" />

        <li>
          Les obligations des parties en vertu des présentes lient leurs
          successeurs, leurs représentants légaux et leurs ayants droit
          autorisés. Le Client ne peut céder ou transférer (par l’effet de la
          loi ou autrement) le présent Contrat (les CGV) ni la licence accordée
          en vertu de ces Conditions Générales de Vente, en tout ou en partie,
          sans le consentement écrit préalable de BUSINESS IMMO. Par exception
          le Client pourra sous réserve d’une notification écrite adressée à
          BUSINESS IMMO, céder ses droits et obligations en vertu des présentes
          à tout successeur de l’ensemble ou de la quasi-totalité des activités
          ou des actifs du Client (par fusion ou autre), à condition que (a)
          cette cession ne débouche pas sur l’élimination ou la réduction du
          flux de revenus de BUSINESS IMMO provenant du Client ou de ce tiers et
          (b) que ce cessionnaire n’entre pas directement ou indirectement en
          concurrence avec BUSINESS IMMO ou l’un de ses affiliés.
        </li>

        <li>
          Enfin, Costar pourra librement céder toute Commande conclues avec le
          Client et ces Conditions Générales de Vente avec toutes société
          appartement au groupe CoStar (la maison mère de BUSINESS IMMO.{" "}
        </li>

        <CgvHeader
          headerNum={21}
          underLinedText="Loi applicable et juridiction compétente"
        />

        <li className="font-bold">
          LES CONDITIONS GENERALES DE VENTE SONT SOUMISES AU DROIT FRANÇAIS.
        </li>

        <li className="font-bold">
          <p>
            AVANT TOUTE ACTION JUDICIAIRE, LES PARTIES CHERCHERONT, DE BONNE
            FOI, A REGLER A L’AMIABLE LEURS DIFFERENDS RELATIFS A LA VALIDITE,
            L’ACCEPTATION, L’EXECUTION, ET/OU L’INTERPRETATION DES CONDITIONS
            GENERALES DE VENTE.{" "}
          </p>

          <p className="mt-4">
            LES PARTIES DEVRONT SE REUNIR AFIN DE CONFRONTER LEURS POINTS DE VUE
            ET EFFECTUER TOUTES CONSTATATIONS UTILES POUR LEUR PERMETTRE DE
            TROUVER UNE SOLUTION AU DIFFEREND QUI LES OPPOSE.{" "}
          </p>

          <p className="mt-4">
            LES PARTIES S’EFFORCERONT DE TROUVER UN ACCORD AMIABLE DANS UN DELAI
            DE TRENTE (30) JOURS A COMPTER DE LA NOTIFICATION PAR L’UN D’EUX DE
            LA NECESSITE D’UN ACCORD AMIABLE, PAR LETTRE RECOMMANDEE AVEC
            DEMANDE D’AVIS DE RECEPTION.
          </p>
        </li>

        <li className="font-bold">
          DANS L’HYPOTHESE OU UNE SOLUTION AMIABLE N’AURAIT PAS ÉTÉ TROUVEE DANS
          CE DELAI, SEULES LES JURIDICTIONS DE PARIS SERONT COMPETENTES POUR
          CONNAITRE DE TOUT LITIGE ENTRE LES PARTIES, Y COMPRIS EN CAS DE
          PLURALITE DE DEFENDEURS, D’APPEL EN GARANTIE, ET/OU DE DEMANDE
          INCIDENTE OU PROCÉDURE D’URGENCE.
        </li>

        <li className="font-bold">
          IL EST CONVENU QUE, NONOBSTANT LES STIPULATIONS DES PARAGRAPHES
          CI-DESSUS, LES PARTIES CONSERVENT EN TOUTES CIRCONSTANCES LA FACULTE
          D’AGIR PAR DEVANT LA JURIDICTION DES REFERES SUR LE FONDEMENT
          NOTAMMENT DES ARTICLES 145, 872 ET 873 DU CODE DE PROCÉDURE CIVIL
        </li>
      </ol>

      <div className="mt-8">
        <p className="text-18">
          Si vous êtes actuellement abonné à Business Immo et avez signé votre
          contrat avant le 1er juin 2024, voir ci-dessous pour accéder aux
          Conditions générales de votre contrat.
        </p>
        <ol className="ml-6 mt-6 grid list-disc gap-2">
          <li className="text-primary hover:underline">
            <Link href={"/cgv/cgv_04_1_2024.pdf"}>Avril 2024</Link>
          </li>
          <li className="text-primary hover:underline">
            <Link href={"/cgv/cgv_27_03_2020.pdf"}>Mars 2020</Link>
          </li>
        </ol>
      </div>
    </div>
  );
};
