"use client";

interface ICookiePreferencesLink {
  title: string;
}

export const CookiePreferencesLink = (props: ICookiePreferencesLink) => {
  return (
    <div>
      <button
        className="font-sans text-content hover:underline"
        onClick={(event) => {
          event.preventDefault();
          if (typeof window !== "undefined") {
            return window.OneTrust?.ToggleInfoDisplay();
          }
        }}
      >
        {props.title}
      </button>
    </div>
  );
};
