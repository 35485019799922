"use client";

import { Fragment, useCallback, useState } from "react";
import { CgvBody } from "./CgvModalBody";
import { Modal, Pane } from "@costar/theme-ui";

type ModalProps = {
  title?: string;
  buttonMessage: string;
  ariaMessage: string;
  onModalClose?: () => void;
  showTermsOfSaleModal?: boolean;
};

export default function CgvModal({
  title,
  buttonMessage,
  ariaMessage,
  onModalClose,
  showTermsOfSaleModal,
}: ModalProps) {
  const [open, setOpen] = useState(showTermsOfSaleModal || false);

  const footerOnClick = useCallback(() => setOpen(true), []);
  const buttonOnClick = useCallback(() => {
    setOpen(false);
    onModalClose?.();
  }, [onModalClose]);

  return (
    <Fragment>
      {open && (
        <Modal
          open={open}
          onClose={buttonOnClick}
          aria-labelledby={ariaMessage}
          aria-describedby={ariaMessage}
          zIndex={50}
          BackdropProps={{
            bg: "rgba(0, 0, 0, 0.2)",
          }}
        >
          <Pane
            absolute
            className="ml-4 h-[85%] w-[90%] max-w-[1080px]"
            border="shadow"
            bg="white"
          >
            <div className="flex h-full flex-col gap-y-5 pb-4 pt-6 sm:pl-4 sm:pt-8">
              <div className="overflow-y-auto">
                <CgvBody />
              </div>
              <button
                onClick={buttonOnClick}
                className="mr-4 self-end rounded-sm bg-primary px-2.5 py-1 text-content"
              >
                {buttonMessage}
              </button>
            </div>
          </Pane>
        </Modal>
      )}
      {!onModalClose && title && (
        <div>
          <button
            onClick={footerOnClick}
            className="font-sans text-content hover:underline"
          >
            {title}
          </button>
        </div>
      )}
    </Fragment>
  );
}
