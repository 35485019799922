"use client";

import { LegalContent } from "@/components/legal-page/legal-content";
import { Modal, Pane } from "@costar/theme-ui";
import { Fragment, useCallback, useState } from "react";

interface ILegalNotice {
  title: string;
  buttonMessage: string;
  ariaMessage?: string;
  primaryPersonUrl: string;
  secondaryPersonUrl: string;
}

export const LegalNoticeModal = (props: ILegalNotice) => {
  const [showLegalNoticeModal, setShowLegalNoticeModal] = useState(false);
  const footerOnClick = useCallback(
    () => setShowLegalNoticeModal(!showLegalNoticeModal),
    [showLegalNoticeModal, setShowLegalNoticeModal]
  );
  const buttonOnClick = useCallback(() => {
    setShowLegalNoticeModal(false);
  }, []);

  const body = (
    <Pane
      absolute
      className="ml-4 h-[85%] w-[90%] max-w-[1080px]"
      border="shadow"
      p="md"
      bg="white"
    >
      <div className="flex h-full flex-col gap-y-5 px-0 py-8 sm:p-8">
        <div className="grow overflow-auto px-8">
          <LegalContent
            primaryPersonLink={props.primaryPersonUrl}
            secondaryPersonLink={props.secondaryPersonUrl}
          />
        </div>
        <div className="px-4 text-right sm:p-0">
          <button
            onClick={buttonOnClick}
            className="rounded-sm bg-primary px-2.5 py-1 text-content"
          >
            {props.buttonMessage}
          </button>
        </div>
      </div>
    </Pane>
  );

  return (
    <Fragment>
      {showLegalNoticeModal && (
        <Modal
          open={showLegalNoticeModal}
          onClose={buttonOnClick}
          aria-labelledby={props.ariaMessage}
          aria-describedby={props.ariaMessage}
          zIndex={50}
          BackdropProps={{
            bg: "rgba(0, 0, 0, 0.2)",
          }}
        >
          {body}
        </Modal>
      )}
      <div>
        <button
          onClick={footerOnClick}
          className="font-sans text-content hover:underline"
        >
          {props.title}
        </button>
      </div>
    </Fragment>
  );
};
