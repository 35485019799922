"use client";

import { Modal, Pane } from "@costar/theme-ui";
import { Fragment, useCallback, useState } from "react";

interface ICookiePolicyModal {
  title: string;
  buttonMessage: string;
  ariaMessage?: string;
  cookiePolicyURL: string;
}

export const CookiePolicyModal = (props: ICookiePolicyModal) => {
  const [showCookieModal, setShowCookieModal] = useState(false);
  const footerOnClick = useCallback(
    () => setShowCookieModal(!showCookieModal),
    [showCookieModal, setShowCookieModal]
  );
  const buttonOnClick = useCallback(() => {
    setShowCookieModal(false);
  }, []);

  const body = (
    <Pane
      absolute
      className="ml-4 h-[85%] w-[90%] max-w-[1080px]"
      border="shadow"
      p="md"
      bg="white"
    >
      <div className="flex h-full flex-col gap-y-5 px-0 py-8 sm:p-8">
        <iframe
          className=" h-full w-full"
          src={props.cookiePolicyURL}
          title="Cookie Policy Page Iframe"
        ></iframe>
        <div className="px-4 text-right sm:p-0">
          <button
            onClick={buttonOnClick}
            className="rounded-sm bg-primary px-2.5 py-1 text-content"
          >
            {props.buttonMessage}
          </button>
        </div>
      </div>
    </Pane>
  );

  return (
    <Fragment>
      {showCookieModal && (
        <Modal
          open={showCookieModal}
          onClose={buttonOnClick}
          aria-labelledby={props.ariaMessage}
          aria-describedby={props.ariaMessage}
          zIndex={50}
          BackdropProps={{
            bg: "rgba(0, 0, 0, 0.2)",
          }}
        >
          {body}
        </Modal>
      )}
      <div>
        <button
          onClick={footerOnClick}
          className="font-sans text-content hover:underline"
        >
          {props.title}
        </button>
      </div>
    </Fragment>
  );
};
