import { Pane } from "@costar/theme-ui";

interface PrivatePolicyModalBodyProps {
  privatePolicyURL: string;
  buttonMessage: string;
  handleClose: () => void;
}

export const PrivatePolicyModalBody = ({
  privatePolicyURL,
  buttonMessage,
  handleClose,
}: PrivatePolicyModalBodyProps) => {
  return (
    <Pane
      absolute
      className="ml-4 h-[85%] w-[90%] max-w-[1080px]"
      border="shadow"
      p="md"
      bg="white"
    >
      <div className="flex h-full flex-col gap-y-5 px-0 py-8 sm:p-8">
        <iframe
          className=" h-full w-full"
          src={privatePolicyURL}
          title="Private Policy Page Iframe"
        ></iframe>
        <div className="px-4 text-right sm:p-0">
          <button
            onClick={handleClose}
            className="rounded-sm bg-primary px-2.5 py-1 text-content"
          >
            {buttonMessage}
          </button>
        </div>
      </div>
    </Pane>
  );
};
